import React from 'react';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import { useServerData } from '../../state/serverDataContext';

const TermsConditionsDefault = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'TermsConditionsDefault' */ './locales/TermsConditionsDefault/TermsConditionsDefault'),
  loading: () => <></>,
});

const TermsConditionsH1Heading = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'TermsConditionsH1Heading' */ './locales/TermsConditionsH1Heading/TermsConditionsH1Heading'
    ),
  loading: () => <></>,
});

export const TermsConditionsPropTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  linkName: PropTypes.string,
  linkUrl: PropTypes.string,
};

const TermsConditions = () => {
  const { name, description, linkName, linkUrl, locale } = useServerData(data => ({
    name: data.pageData?.termsConditions.name,
    description: data.pageData?.termsConditions.description,
    linkName: data.pageData?.termsConditions.linkName,
    linkUrl: data.pageData?.termsConditions.linkUrl,
    locale: data.locale,
  }));

  const LocaleTermsConditions =
    {
      'en-mt': TermsConditionsH1Heading,
      'en-nz': TermsConditionsH1Heading,
      'en-is': TermsConditionsH1Heading,
      'en-si': TermsConditionsH1Heading,
      'en-lu': TermsConditionsH1Heading,
      'en-mu': TermsConditionsH1Heading,
      'en-uk': TermsConditionsH1Heading,
      'en-ir': TermsConditionsH1Heading,
    }[locale] || TermsConditionsDefault;

  return (
    <LocaleTermsConditions
      data-testid="terms-conditions"
      name={name}
      description={description}
      linkName={linkName}
      linkUrl={linkUrl}
    />
  );
};

export default TermsConditions;
